import React from 'react';
import LegislationType from "../../LegislationType/LegislationType";
import { connect } from "react-redux";
import { setWorkplaceSavingsLegislationTypeYou } from "../../../../../store/actions/savingsAndInvestments";

const WorkplaceSavingsLegislationYou = ( props ) => {

    function onChange(val) {
        props.setWorkplaceSavingsLegislationTypeYou(val);
    }

    return (
        <LegislationType
            title={'Your'}
            value={props.workplaceSavingsLegislationYou}
            onChange={onChange}
        />
    )
};

function mapStateToProps(state) {
    return {
        workplaceSavingsLegislationYou: state.savingsAndInvestments.you.workplaceSavings.legislationType
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setWorkplaceSavingsLegislationTypeYou: val => dispatch(setWorkplaceSavingsLegislationTypeYou(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkplaceSavingsLegislationYou);