import React, { useCallback, useEffect, useState } from 'react';
import Layout from "../../../../hoc/Layout/Layout";
import { Helmet } from "react-helmet";
import InnerLayoutStep from "../../../../hoc/InnerLayoutStep/InnerLayoutStep";
import InnerLayoutStepContent from "../../../../hoc/InnerLayoutStepContent/InnerLayoutStepContent";
import Navigation from "../../../../components/Navigation/Navigation";
import { connect } from "react-redux";
import WorkplaceSavingsLegislationYou
    from "../../../../components/ContentRow/Step3/WorkplaceSavings/WorkplaceSavingsLegislation/WorkplaceSavingsLegislationYou";
import WorkplaceSavingsLegislationSpouse
    from "../../../../components/ContentRow/Step3/WorkplaceSavings/WorkplaceSavingsLegislation/WorkplaceSavingsLegislationSpouse";
import { thirdStepCompleted } from "../../../../store/actions/savingsAndInvestments";
import { retirementCalcLinks } from "../../../../Routes/RoutesConst";

const WorkplaceSavingsLegislationType = ( props ) => {
    const titles = {
        layoutTitle: 'WORKPLACE SAVINGS',
        sectionTitle: 'Please select the province where your assets were accumulated.',
    }

    const title = "Workplace savings legislation"

    const [next, setNext] = useState(retirementCalcLinks.properties);

    const nextLink = useCallback(() => {
        if(props.you.lockedIn.exist || props.spouse.lockedIn.exist) {
            return retirementCalcLinks.lockedInHave;
        }else if(props.you.tfsa.exist || props.spouse.tfsa.exist){
            return retirementCalcLinks.tfsaHave;
        }else if(props.you.nonRegistered.exist || props.spouse.nonRegistered.exist){
            return retirementCalcLinks.nonRegisteredHave;
        }else {
            return retirementCalcLinks.properties;
        }
    }, [props]);

    useEffect(() => {
        props.thirdStepCompleted(false);
        setNext(nextLink());
    }, [props, nextLink]);

    return (
        <Layout
            title={titles.layoutTitle}
            progressStep3={(6/16)*100}
            active={'step3'}
        >
            <Helmet>
                <title>{title}</title>
            </Helmet>

            <InnerLayoutStep
                modalContent='workplaceSavingsLegislationType'
                sectionTitle={titles.sectionTitle}
            >

                {
                    props.you.workplaceSavings.exist &&
                    <InnerLayoutStepContent type={'you'}>
                        <WorkplaceSavingsLegislationYou />
                    </InnerLayoutStepContent>
                }

                {
                    props.isSpouse && props.spouse.workplaceSavings.exist &&
                    <InnerLayoutStepContent type={'spouse'}>
                        <WorkplaceSavingsLegislationSpouse />
                    </InnerLayoutStepContent>
                }

            </InnerLayoutStep>

             <Navigation
                 isCompleted={true}
                 next={next}
             />

        </Layout>
    )
};

function mapStateToProps(state) {
    return {
        isSpouse: state.aboutYou.myFamily.spouseData.isSpouse,
        you: state.savingsAndInvestments.you,
        spouse: state.savingsAndInvestments.spouse,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        thirdStepCompleted: (val) => dispatch(thirdStepCompleted(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkplaceSavingsLegislationType);