import React from 'react';
import LegislationType from "../../LegislationType/LegislationType";
import { connect } from "react-redux";
import { setWorkplaceSavingsLegislationTypeSpouse } from "../../../../../store/actions/savingsAndInvestments";

const WorkplaceSavingsLegislationSpouse = ( props ) => {

    function onChange(val) {
        props.setWorkplaceSavingsLegislationTypeSpouse(val);
    }

    return (
        <LegislationType
            title={'Spouse\'s'}
            value={props.workplaceSavingsLegislationSpouse}
            onChange={onChange}
        />
    )
};

function mapStateToProps(state) {
    return {
        workplaceSavingsLegislationSpouse: state.savingsAndInvestments.spouse.workplaceSavings.legislationType
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setWorkplaceSavingsLegislationTypeSpouse: val => dispatch(setWorkplaceSavingsLegislationTypeSpouse(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkplaceSavingsLegislationSpouse);